<template>

  <div class="pbf">

    <template v-if="strainData?.id">

      <StrainHead
        :id="strainData.id"
        :nm="strainData.name"
        :nmalt="strainData.name"
        :aka="strainData.name_aka"
        :info="strainData.description"
        :avatar="strainData.cover_s"
        :link="strainData.section"
        :rate="strainData.item_stat?.avg_rate"
        :cnt-reports="strainData.item_stat?.cnt_reports"
        :cnt-harvests="strainData.item_stat?.cnt_harvests"
        :positive="strainData.props.seed_strain_effects_positive"
        :negative="strainData.props.seed_strain_effects_negative"
        :medical="strainData.props.seed_strain_medical"
        :tastes="strainData.props.seed_strain_tastes"
        :height="strainData.props.seed_strain_height"
        :flowering="strainData.props.seed_strain_flowering_days"
        :genes="strainData.props.seed_strain_genes"
        :genes-per="strainData.props.seed_strain_genes_per"
        :yield="strainData.props.seed_strain_yield"
        :difficulty="strainData.props.seed_strain_difficulty"
        :cbd="strainData.props.seed_strain_cbd"
        :thc="strainData.props.seed_strain_thc"
        :grow-journals-link="'/grow-journals/' + strainData.section"
        :creator="strainData.item_product_creator"
        :bread="[
          {name: 'Strains', link: '/strains'},
          {name: strainData.name, link: strainData.section},
        ]"
        />


      <div class="pb grid_width">  

        <UiTabs 
          :tabs="useStrain().getTabs(strainData)" 
          :acttab="activeTab"
        />    

        <br>

        <NuxtPage 
          :strain-data="strainData"
          :id="strainData.id"
        />
        
      </div>

      

    </template> 
    
    <template v-else>
      <SkBrandOpened />
    </template> 
      
  </div>
</template>

<script setup lang="ts">

import type { Strain } from '@/types/strain'

const route = useRoute();
const router = useRouter();
const {$api, $ga, $popup} = useNuxtApp()

var link = ref(route.params['id']);
var activeTab = ref('info');


const loadData = async function(strainLink: string) {     
  const response = await $api.getUrl('/ref/strains/' + strainLink, {
  })  
  return response;  
}


const { status: strainDataStatus,  data: strainData } = await useLazyAsyncData('strain_data_' + link.value, async () => await loadData(link.value) as Strain)


watch( () => strainData, () => {
  useHead(useNuxtApp().$head.getStrain(strainData.value));
}, {immediate: true, deep: true});

watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getStrain(strainData.value));
}, {immediate: true, deep: true});


onBeforeUnmount(() => {
  strainData.value = null;
})

useHead(useNuxtApp().$head.getStrain(strainData.value));

  
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
