import { useNuxtApp } from '#app'
// import type { Diary, Week, WeekNutrient } from '@/types/diary'
// import { WeekFazaLetter, WeekFaza } from '@/types/diary'
import type { Strain } from '@/types/strain'

/**
 * useStrain — logic (CRUD, likes, subscriptions) for the "User" entity
 */

export function useStrain() {
  const { $api, $popup, $follow, $convert } = useNuxtApp()
  
  
  function getTabs(strain: Strain){

    var items = [];

    items.push({
      id: 'info',
      name: 'Info',
      link: strain?.link + '',
      count: 0,
      icon: '',
    });
  
    if(strain?.item_stat?.cnt_reports){
      items.push({
        id: 'diaries',
        name: 'Diaries',
        link: strain?.link + '/diaries',
        count: strain?.item_stat?.cnt_reports,
        icon: '',
      });
    }
  
   
    if(strain?.item_stat?.cnt_products){
      items.push({
        id: 'Products',
        name: 'Products',
        link: strain?.link + '/products',
        count: strain?.item_stat?.cnt_products,
        icon: '',
      });
    }
  
    if(strain?.item_stat?.cnt_harvests){
      items.push({
        id: 'Harvests',
        name: 'Harvests',
        link: strain?.link + '/harvests',
        count: strain?.item_stat?.cnt_harvests,
        icon: '',
      });
    }
  
    if(strain?.item_stat?.cnt_reviews){
      items.push({
        id: 'reviews',
        name: 'Reviews',
        link: strain?.link + '/reviews',
        count: strain?.item_stat?.cnt_reviews,
        icon: '',
      });
    }
  
    return items;
  }



  return {
    getTabs,
  }
}