<template>

  <div class="head pb grid_width "> 
  
      <div class="bgbr">
        <UiBreadBack
          :list="props.bread"
        /> 
      </div>

      <div class="phead">

        <div class="ava">
          <img 
            data-not-lazy
            :src="props.avatar" 
            class="avai" 
            :alt="props.nmalt"
            @click="handleOpenGallery"
            > 
            <div v-if="props.itemsPhoto?.length" class="nums">1 / {{ props.itemsPhoto?.length }}</div>
        </div>

   
        <div class="p_name">


          <h1 class="name">{{ nm }}</h1> 

          <div class="brand">                
            {{ props.aka }}
          </div>
 
        </div>

        <!--  -->


        <div class="space1"></div>
        <div class="space2"></div>


        <!--  -->


        <div class="actions hidden"> 


          <UiButton
            v-if="props.linkShop"
            :target="'_blank'"
            :ico="'icon-cart'"
            :to="props.linkShop"
            :name="$t('strain_view_buy_now_button')"
            type="red"
            tag="NuxtLink"
            />

          </div>  


        <!--  -->

        <div class="stats mob">
 
          <div class="stat">
            <div class="vl"><span class="star_rate"></span><span v-if="props.rate">{{ formatRate(props.rate) }}</span></div>
            <div class="tx">
              {{ props.cntHarvests || 0 }} {{ $plurals.get('harvest', props.cntHarvests) }}
            </div>
          </div>


          <div class="stat" v-if="genes">
            <div class="vl">{{ genes }}</div>
            <div class="tx">Genes</div>
          </div>

          <div class="stat" v-if="thc">
            <div class="vl">{{ thc }}%</div>
            <div class="tx">THC</div>
          </div>

          <div class="stat" v-if="cbd">
            <div class="vl">{{ cbd }}%</div>
            <div class="tx">CBD</div>
          </div>

          <NuxtLink :to="props.growJournalsLink" class="stat" v-if="props.cntReports">
            <div class="vl">
              <i class="icon-diary" title="diaries"></i>
              {{ props.cntReports }}
            </div>
            <div class="tx">Diaries</div>
          </NuxtLink>
          


          <NuxtLink :to="props.creator.link" class="stat creator" v-if="props.creator">
            <div class="vl">
              {{ props.creator.item_brand.name }}
            </div>
            <div class="tx">Strain Creator</div>
          </NuxtLink>
   

          
        </div>

        <div class="stats total">
 
          <div class="stat stars">
            <div class="vl"><span class="star_rate"></span><span v-if="props.rate">{{ formatRate(props.rate) }}</span></div>
            <div class="tx">
              {{ props.cntHarvests || 0 }} {{ $plurals.get('harvest', props.cntHarvests) }}
            </div>
          </div>
          
          <div class="stat" v-if="genes">
            <div class="vl">{{ genes }}</div>
            <div class="tx">Genes</div>
          </div>

          <div class="stat" v-if="thc">
            <div class="vl">{{ thc }}%</div>
            <div class="tx">THC</div>
          </div>

          <div class="stat" v-if="cbd">
            <div class="vl">{{ cbd }}%</div>
            <div class="tx">CBD</div>
          </div>

          <NuxtLink :to="props.growJournalsLink" class="stat" v-if="props.cntReports">
            <div class="vl">
              <i class="icon-diary" title="diaries"></i>
              {{ props.cntReports }}
            </div>
            <div class="tx">Diaries</div>
          </NuxtLink>



          <NuxtLink :to="props.creator.link" class="stat creator" v-if="props.creator">
            <div class="vl">
              {{ props.creator.item_brand.name }}
            </div>
            <div class="tx">Strain Creator</div>
          </NuxtLink>
   


        </div>

        <!--  -->

        <div class="desc">
          
          <GeneralShowMore
            :desktop-max-height="60"
            :mobile-max-height="80" 
            >
            <div v-if="props.info" v-html="props.info"></div> 
          </GeneralShowMore>

          <StrainHeadEffects
            :positive="props.positive"
            :negative="props.negative"
            :medical="props.medical"
            :tastes="props.tastes"
            :genes="props.genesPer"
            />
          
        </div>

        <!--  -->

    


      </div>
 
       
  </div>



  <GeneralLightboxVertical 
    v-if="lightboxShow"
    :photo-id="lightboxId" 
    :photos="lightboxPhotos" 
    @close="lightboxShow = false" 
  />

</template>

<script setup lang="ts">

import type { Product } from '~/types/product'

interface PageProps {
  id: number;
  nm: string;
  nmalt: string;
  aka: string;
  info: string;
  avatar: string;
  link: string;
  rate: number;
  rateof?: number;
  cntHarvests: number;
  cntReports: number;
  positive: string;
  negative: string;
  medical: string;
  tastes: string;
  height: string;
  flowering: number;
  genes: string;
  genesPer: number;
  yield: string;
  difficulty: string;
  cbd: number;
  thc: number;
  growJournalsLink: string;
  bread: any[];
  creator?: Product
}

const emits = defineEmits(['follow', 'unfollow'])

const props = withDefaults(defineProps<PageProps>(), {
  rateof: 10,
})


var lightboxShow = ref(false);
var lightboxId = ref(null);
 



const lightboxPhotos = computed(() => {
  
    var items = []; 

    let photo = {}; 
    photo.base = props.avatar;
    photo.zoom = props.avatar;
    items.push(photo);  


    // if(!props.itemsPhoto){
    //   return items;
    // }

    // for(var i of props.itemsPhoto){
    //   let photo = {}; 
    //   photo.base = i.url_m;
    //   photo.zoom = i.url_l; 
    //   items.push(photo);        
    // }
    return items;
})

const handleOpenGallery = function(){
  lightboxShow.value = true;
  lightboxId.value = 0;
}
 

// 9 > 9.0
// 9.1 > 9.1
const formatRate = (rate: number) => {
  if(!rate){
    return 0;
  }
  return rate.toFixed(1);
}


</script>




<style scoped>
 
.pghd_gr3{

}
.head{
  padding: 0!important;
} 

.head .head_bg .page_content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.head .head_bg .head_info {
  display: flex;
  align-items: center;
  width: 100%;
}
.head .head_bg .item_name {
  display: flex;
  align-items: center;
  z-index: 12;
  position: relative;
}
.head .head_bg .item_name .item_logo {
  width: 130px;
  /* background-color: var(--un-background-color-gray); */
  background-color: white;
  padding: 2px;
  border-radius: 200px;
  margin-right: 1rem;
  margin-top: -43px;
  border: 4px var(--un-element-color-gray) solid;
  box-shadow: 0px 0px 20px #00000024;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.head .bg{
  height: 230px;
  margin-bottom: -64px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
} 

.cap {
  text-transform: capitalize;
} 
/*  */

.phead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 1rem 2rem;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-areas: 
    "ava space1 space1"
    "ava name actions"
    "ava stats_total stats_total"
    "ava desc desc"
    "ava space2 space2"
  ;
}

.phead .ava {
  width: 100%;

  padding: 2px;
  border-radius: 15px; 
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  grid-area: ava;
  position: relative;
  height: 100%;
}
.phead .ava .nums{
  position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: white;
    padding: 2px 11px;
    border-radius: 60px;
    font-weight: bold;
    box-shadow: 0px 0px 10px #00000038;
}
.phead .ava .avai{
  width: 100%;
  border-radius: 15px;
  aspect-ratio: 191/267;
  object-fit: cover;
}
 

.phead .space1 {
  grid-area: space1;
}

.phead .space2 {
  grid-area: space2;
}

.phead .p_name {
  display: flex;
  flex-direction: column;
  grid-area: name;
}

.phead .p_name .name {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4rem;
}

.phead .p_name .aka {
  color: var(--un-text-color-gray-light);
  margin-top: 0.3rem;
}
.phead .p_name .brand{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  text-transform: capitalize;

}
.phead .p_name .brand .bname{
  font-size: 0.9rem;
}

.phead .p_name .brand .blogo {
  width: 50px;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.phead .p_name .brand .blogo .logo {
  
}

.phead .stat.stat_gp .gp {
  width: 20px;
}
.phead .stats .creator .vl{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  max-width: 370px;
  width: 100%;
  display: block !important;
}
.phead .stats.total {
  grid-area: stats_total;
}
.phead .stats.mob {
  grid-area: stats_mob;
  display: none;
}
.phead .stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
}
.phead .actions{
  display: flex;
  gap: 0 0.5rem;
  grid-area: actions;
 
}
.phead .stats .stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 45px;
}

.phead .stats .stat .vl {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;

  text-transform: capitalize;
}

.phead .stats .stat .icon-female {
  color: #ff0067;
} 
.phead .stats .stat .icon-male {
  color: #0068ff;
}
.phead .stats .stat .shp{
  font-size: 1.4rem; 
  font-family: Courier;
  text-transform: uppercase;
  font-weight: normal;
    line-height: 1.5rem;
}
.phead .stats .stat .shp.auto{
  color: #1eb353;
}
.phead .stats .stat .shp.photo{
  color: #a913d9;
}

.phead .stats .stat .tx {
  color: #898989;
  font-size: 0.8rem;
}

.phead .stats .stat.taste .vl {
  display: flex;
  align-items: center;
}

.phead .stats .stat.taste .vl .ico:nth-child(1) {
  width: 25px;
  height: 25px;
}

.phead .stats .stat.taste .vl .ico:nth-child(2) {
  width: 20px;
  height: 20px;
}

.phead .stats .stat.taste .vl .ico:nth-child(3) {
  width: 15px;
  height: 15px;
}

.phead .desc {
  color: #7c7c7c;
  grid-area: desc;
  white-space: pre-wrap;
}
.phead .desc :deep(p){
  margin: 0;
}
.phead .desc:deep(a) {
  color: var(--un-firm);
  text-decoration: underline;
}


@container pb (max-width: 650px) {

  .head .breadcrumbs{
    /* display: none; */
  }
 

  .phead{
    /* margin-top: 1rem; */
    gap: 0.5rem 0rem;
    grid-template-columns: 65% auto;
    grid-template-areas: 
      "name name"
      "ava space1"
      "ava stats_mob" 
      "ava space2"
      "stats_total stats_total"
      "desc desc"
    ;
    
  }
  .phead .ava{
    width: 100%;
  } 
  .phead .actions{
    flex-wrap: wrap;
  }
  .phead .actions :deep(.btn){
    width: 100%;
  }


  .phead .desc {

  }

  .phead .stats .stat{

    width: 100%;
    overflow: hidden;
  }

  .phead .stats.total {
    display: none;
  }
  .phead .stats.mob {
    display: grid;
    grid-template-columns: 1fr ;
    margin-right: 1rem;
  }

  .phead .stats .stat.stars {
    display: none;
  }
  .phead .stats .stat.rate {
    display: none;
  }

  .phead .stats.mob .vl{
    font-size: 1.3rem;
  }
  .phead .stats.mob .star_rate{
    width: 25px;
    height: 25px;
  }


  .phead .stats.mob .creator .vl{    
    font-size: 1rem;

    max-width: 100%;
        white-space: break-spaces;
        text-align: center;
        line-height: 1rem;
  }


}


/* BACKGROUND */
 


@media (max-width: 650px) {

  .head .bg{
    height: 180px;

    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position-y: center;
    background-position-x: center;
  } 
}

@container pb (max-width: 600px) {

  .head .bgbr{

  }
  
}



/* brand_cover  */


.brand_cover{

}
.brand_cover .bc{
    width: 100%;
    border-radius: 15px;    
}
.brand_cover .bcm{
  display: none;
}




@container pb (max-width: 650px) {
  .brand_cover{

  }
  .brand_cover .bc{
      display: none; 
  }
  .brand_cover .bcm{ 
    max-width: none;
    width: calc(100% + 4rem);
    display: block;
    margin-left: -2rem;
    margin-top: -2rem;
    margin-bottom: -20px;
  }
}

/*  */





/* user_official */



.user_official {
    position: relative;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    margin-right: 20px;
    height: 30px;
}

.user_official .avatar {
    width: 40px;
    position: relative;
    height: 40px;
}

.user_official .avatar img {
    border-radius: 50%;
    width: 100%;
    border: 2px white solid;
}

.user_official .info {
    width: calc(100% - 50px);
    margin-left: 10px;
    line-height: 1.1rem;
}

.user_official .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    bottom: 2px;
}

.user_official .info .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #494949;
}

.user_official .info .status {
    color: #9c9c9c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


</style>
